import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSeasonsList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'code', sortable: true },
        { key: 'startYear', label: 'START', sortable: true },
        { key: 'endYear', label: 'END', sortable: true },
        { key: 'status', sortable: true },
        { key: 'startDate', sortable: true },
        { key: 'endDate', sortable: true },
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('code')
    const isSortDirDesc = ref(true)

    // const meta = computed(() => {
    //     const localItemsCount = refSeasonListTable.value ? refSeasonListTable.value.localItems.length : 0

    //     return {
    //         from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
    //         to: perPage.value * (currentPage.value - 1) + localItemsCount,
    //         of: totalData.value,
    //     }
    // })

    const refetch = () => {
        console.log('seasons refetch', records.value)
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-season/list', {
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { seasons, meta } = response.data

            meta.value = meta

            callback(seasons)
            totalRecords.value = meta.value.total
            from.value = meta.value.from
            to.value = meta.value.to
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching seasons list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    const resolveStatusVariant = status => {
        if(status) return 'success'
        return 'info'
    }

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
    
        // resolveUserRoleVariant,
        // resolveUserRoleIcon,
        resolveStatusVariant,
        refetch,
    
        // Extra Filters
        // roleFilter,
        // planFilter,
        // statusFilter,
    }
}